import * as React from "react"
import { Helmet } from "react-helmet"

import Layout from '../components/layout'

// Import from a CSS file in your src
import "../styles/lcars-classic.min.css"

export default function BeerTrek () {
  return (
  <Layout>
    <Helmet title="Advanced Rules" defer={false} />
    <div class="lcars-text-bar">
      <span>Beer Trek - The Next Level</span>
    </div>
    <div style={{'color': '#ff7700'}}>
      <p>
      So you're <i>really</i> into Beer Trek, huh? Got all
      the rules down? Can quote them with your eyes closed? Want to be miserable when you get up
      to go to work after Star Trek Night? Then I guess you're ready to play with <b>all</b> the
      rules we play with on Star Trek Night. Here they are, in no particular order.
      <hr/>
      </p>

      <h3>False Buzzage</h3> 
      <p>is when you yell out a drink that turns out not to be the case. Someone else
      has the right to tell you to "Drink for false buzzage." However, the great
      symmetry built in to Beer Trek requires them to drink as well; they must raise their glass
      and say, "I shall also drink in sympathy."
      </p>
      <hr/>

      <h3>Making the Call</h3> 
      <p>When someone guesses how the episode is going to turn out way in advance,
      (and they guess right) they can tell everyone else, "Drink when someone makes the
      call!" Of course, they must then raise their glass and say, "I shall also drink
      in celebration!"</p>
      <hr/>

      <h3>Inverse Drink</h3><p> - the most dangerous drink in Beer Trek. Inverse drinks occur when you see
      the inverse of a situation that would have been a drink. For example, you drink when the
      wormhole opens; when the wormhole closes, you can drink for an Inverse Drink.&nbsp; Other
      favs: drink when a ship cloaks, drink when Data <em>does</em> get it, drink for 53% of
      something.&nbsp; Keep your eyes open for these and be creative!</p><hr/>

      <h3>Implicit Drink</h3><p> - a drink that you <i>know</i> happened, even though it happened off screen.
      For example, Riker walks in holding a clipboard; then in the next shot, Picard is holding
      that clipboard. Even though there was no on-screen clipboard pass, you can still drink for
      an implicit drink. There are <i>many</i> implicit drinks if you look hard enough. For
      example, every time a ship goes through the wormhole, there are 4 drinks: one drink for
      when you see it open, one for when you see it close (the inverse drink), and 2 implicit
      drinks for when it opened and closed on the other end.</p><hr/>

      <h3>Drink Tease</h3><p> - a drink that sure looked like it was going to happen, but doesn't. For
      example, Riker walks in holding a clipboard; if he doesn't pass it to anyone, drink for a
      drink tease.</p><hr/>

      <h3>Neelix Loops</h3><p> - occur when we get caught in an infinite drink loop. They take their name
      from the fact that when someone says "Neelix", you must drink and say
      "Neeeelix!" So then of course you must drink and say "Neeelix!" and so
      on. Repeat until you are either too drunk or laughing too hard to take another drink. The
      most notorious Neelix Loop is caused by the rule, "Drink when someone drinks",
      since everyone is always drinking...</p><hr/>

      <h3>Drinks in the Buffer</h3><p> - when you can't keep up with the rules and fall too far
      behind in your drinking, you've got too many drinks in the buffer. Drink once to clear the buffer!</p><hr/>

      <h3>Gonzo Drinks</h3><p> - sometimes a single line will have so many drinks in it
      that even "too many drinks in the buffer" doesn't cover it. For example, you
      know that episode where Worf says "Sir, I've run 13 diagnostics and I can't see what
      is wrong." 13 diagnostics?!?! That's a gonzo drink! So fall out of your chair like
      you're on TOS, and yell "Waggggh!"; then drink.</p><hr/>

      <h3>MetaRules</h3>
      <p>are rules about rules. Our current list of MetaRules is:</p>
      <ul class="lcars-list">
        <li>Drink when someone makes up a new rule.</li>
        <li>Drink when someone makes up a new MetaRule.</li>
        <li>Drink for rule quibble (when we argue about whether
          something should be a rule or not).</li>
        <li>Drink when someone makes up a rule just in time. For
          example, you make the rule "Drink when one of the main characters is in the
          brig", and later in the episode, <i>four</i> characters are in the brig. Whew! You
          made up that rule just in time! So drink!</li>
      </ul>
    </div>
  </Layout>
)}
